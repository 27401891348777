import styled from 'styled-components';

export default styled.div`
  flex: 1;
  background: var(--bg-pastel-pink);

  img {
    width: 100%;
    height: 300px;
  }
`;
