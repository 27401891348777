import HeadSeo from '@uikit/headers/HeadSeo';
import NotFound from 'components/404NotFound';
import { NAMESPACES_I18N } from 'configs/constants';
import { SEO_VAR } from 'configs/seo';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const NotFoundPage = () => {
  const { push } = useRouter();

  useEffect(() => {
    push('/');
  }, [push]);

  return (
    <>
      <HeadSeo item={SEO_VAR.notFound404} hiddenMetaTitleDesc />
      <NotFound />
    </>
  );
};

export default NotFoundPage;

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, NAMESPACES_I18N)),
  },
});
