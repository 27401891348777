import { Button, Result } from 'antd';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import NotFoundWrapper from './styles';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <NotFoundWrapper>
      <Result
        subTitle="Sorry, we couldn’t find the page you are looking for"
        icon={<img alt="404" src="/svg/404-error.svg" />}
        extra={
          <Link href="/">
            <Button size="large" shape="round" type="primary">
              {t('button.backToHomepage')}
            </Button>
          </Link>
        }
      />
    </NotFoundWrapper>
  );
};

export default NotFound;
